import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import { Layout } from "../components/layout"
import {
  Hero,
  Container,
  FeatureCallout,
  Button,
} from "@revfluence/aspire-www-ui"
import { PageSection } from "../components/page-section"
import SEO from "../components/seo"
import { StrategyForm } from "../components/strategy-form"

const SectionContentDiv = styled.div`
  margin-bottom: 32px;
`
const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export default function Strategies({ data }) {
  return (
    <Layout>
      <SEO
        title="Ready for the 2020 holiday season? Download our holiday playbook"
        description="In our definitive holiday playbook, we pair data and strategies to arm you with all the knowledge you need to succeed on Black Friday and beyond."
        url="holiday.aspireiq.com/strategies"
      />
      <Hero size="sm">{null}</Hero>
      <Container>
        <PageSection>
          <FeatureCallout
            mediaPosition="right"
            headline="The definitive holiday playbook"
            subHeadline=""
            Copy={() => (
              <div>
                <SectionContentDiv>
                  The holidays came early — and your present is one download
                  away.
                </SectionContentDiv>
                <SectionContentDiv>
                  In our definitive holiday playbook, we pair data and
                  strategies to arm you with all the knowledge you need to
                  succeed on Black Friday and beyond.
                  <br />
                  Download the playbook to learn:
                </SectionContentDiv>
                <ul>
                  <li>How consumers are behaving</li>
                  <li>Ways brands can inspire loyalty</li>
                  <li>Five strategies for seasonal success</li>
                </ul>
              </div>
            )}
            Media={() => (
              <div>
                <StrategyForm />
              </div>
            )}
            shouldShowMediaOnMobile={true}
            Cta={() => null}
          />
        </PageSection>
        <PageSection>
          <ImageContainer>
            <Img fixed={data.ebook.childImageSharp.fixed} />
          </ImageContainer>
        </PageSection>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    ebook: file(relativePath: { regex: "/ebook/" }) {
      childImageSharp {
        fixed(width: 640, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
