import React, { useState } from "react"
import styled from "styled-components"
import { SurveyForm } from "./survey-form"
import { FeatureHighlight } from "@revfluence/aspire-www-ui"
import { Link } from "./Link"

const DownloadContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const StrategyForm = () => {
  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleSubmit = () => {
    setIsSubmitted(true)
  }

  return (
    <React.Fragment>
      {!isSubmitted && <SurveyForm onSuccess={handleSubmit} />}
      {isSubmitted && (
        <React.Fragment>
          <DownloadContainer>
            <FeatureHighlight
              icon="file-download"
              headline="Download"
              Link={Link}
              url={
                "https://learn.aspireiq.com/rs/982-DON-266/images/Holiday_eBook_2020.pdf"
              }
            />
          </DownloadContainer>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
