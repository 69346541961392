import React, { useState } from "react"
import styled from "styled-components"
import { Heading } from "rebass"
import { Button } from "@revfluence/aspire-www-ui"
import { useScript } from "../helpers/hooks"

const FormHeader = styled.h2`
  color: #276ee1;
`
const Form = styled.form`
  display: flex;
  flex-direction: column;
`
const Input = styled.input`
  background: #f3f6fc;
  border-radius: 3px;
  margin-bottom: 16px;
  border: none;
  padding: 4px;
`

const emptyFunc = () => null

export const SurveyForm = ({
  onSuccess = emptyFunc,
  onFailure = emptyFunc,
}) => {
  const [marketoScriptLoaded, marketoError] = useScript(
    "https://app-ab34.marketo.com/js/forms2/js/forms2.min.js"
  )

  const loaded = marketoScriptLoaded

  const [form, setForm] = useState(null)
  const [fields, setFields] = useState({
    email: "",
    firstName: "",
    lastName: "",
    company: "",
  })

  const handleScriptLoad = () => {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    MktoForms2.loadForm(
      "//app-ab34.marketo.com",
      "982-DON-266",
      1187,
      (form) => {
        form.onSuccess((v) => {
          return false
        })
        setForm(form)
      }
    )
  }

  const handleChange = (event) => {
    setFields({
      ...fields,
      [event.target.name]: event.target.value,
    })
  }

  const validateForm = () => {
    return Object.values(fields)
      .map((val) => !!val)
      .every((field) => field === true)
  }

  const submitForm = (event) => {
    event.preventDefault()
    const validated = validateForm()

    if (!validated) return onFailure()

    form.addHiddenFields({
      Email: fields.email,
      FirstName: fields.firstName,
      LastName: fields.lastName,
      Company: fields.company,
    })
    form.submit()
    onSuccess()
  }

  // this should cause this to load the marketo form only once.
  if (loaded && form === null) handleScriptLoad()

  return (
    <div>
      {!loaded && <Heading>Loading Form...</Heading>}
      {loaded && (
        <React.Fragment>
          <FormHeader>Download the report</FormHeader>
          <Form name="strategy" onSubmit={submitForm}>
            <Input
              placeholder="Email"
              name="email"
              value={fields.email}
              onChange={handleChange}
              required
            />
            <Input
              placeholder="First Name"
              name="firstName"
              value={fields.firstName}
              onChange={handleChange}
              required
            />
            <Input
              placeholder="Last Name"
              name="lastName"
              value={fields.lastName}
              onChange={handleChange}
              required
            />
            <Input
              placeholder="Company"
              name="company"
              value={fields.company}
              onChange={handleChange}
              required
            />
            <Button type="submit">Download</Button>
          </Form>
        </React.Fragment>
      )}
    </div>
  )
}

export default SurveyForm
